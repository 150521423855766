import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import CounterUp from '../global/CounterUp'
import Values from "../About/Values";
import Map from "../Contact/MapContent";

// Arrglo de precio de platos por categoria
const priceMenu = [
    {
        name: 'Breakfast Burritos',
        priceDetail: [
            {
                title: 'Machaca',
                price: 7.25,
            },
            {
                title: 'Bacon',
                price: 7.25,
            },
            {
                title: 'Longaniza',
                price: 7.25,
            },
            {
                title: 'Ham/Jamon',
                price: 7.25,
            },
            {
                title: 'Chicharrones',
                price: 7.75,
            },
            {
                title: 'Ultimate',
                price: 7.99,
            }
        ]
    },
    {
        name: 'Breakfast Combo',
        priceDetail: [
            {
                title: 'Chilaquiles',
                price: 10.25,
            },
            {
                title: 'Chicharrones.',
                price: 8.50,
            },
            {
                title: 'Huevos Rancheros',
                price: 10.25,
            },
            {
                title: 'Huevos a la Mexicana',
                price: 8.99,
            },
            {
                title: 'Nopales con Huevo',
                price: 8.75,
            },
            {
                title: 'Jamon con Huevo',
                price: 7.99,
            },
            {
                title: 'Longaniza con Nopales Plate',
                price: 8.25,
            },
            {
                title: 'Huevo con Tocino & Papas Plate',
                price: 7.99,
            },
        ]
    },
    {
        name: 'Quesadilla',
        priceDetail: [
            {
                title: 'Carne Asada',
                price: 8.50,
            },
            {
                title: 'Poblana',
                price: 8.50,
            },
            {
                title: 'Adobada',
                price: 8.25,
            },
            {
                title: 'Pollo Asado',
                price: 8.25,
            },
            {
                title: 'Camarones/Shrimp',
                price: 8.25,
            },
            {
                title: 'Cheese',
                price: 5.25,
            },
            {
                title: 'Tinga Quesadilla',
                price: 7.25,
            },
            {
                title: 'Hot Cheetos Quesadilla',
                price: 8.50,
            }
        ]
    },
    {
        name: 'Combos',
        priceDetail: [
            {
                title: 'Carne Arrachera',
                price: 10.99,
            },
            {
                title: 'Bistek Ranchero',
                price: 10.99,
            },
            {
                title: 'Camarones a la Diabla',
                price: 8.99,
            },
            {
                title: 'Chile Relleno',
                price: 7.99,
            },
            {
                title: 'Mole Poblano con Pollo',
                price: 8.50,
            },
            {
                title: 'Pollo Asado.',
                price: 8.50,
            },
            {
                title: 'Enchiladas',
                price: 8.50,
            }
        ]
    },
    {
        name: 'Tortas',
        priceDetail: [
            {
                title: 'Carne Asada.',
                price: 8.25,
            },
            {
                title: 'Mi Ranchito',
                price: 8.25,
            },
            {
                title: 'Tortas De Pollo Asado',
                price: 7.99,
            },
            {
                title: 'Tortas Adobada',
                price: 7.99,
            },
            {
                title: 'Milanesa',
                price: 8.25,
            },
            {
                title: 'Ham/ Jamon',
                price: 7.99,
            },
            {
                title: 'Tortas Longaniza',
                price: 7.99,
            }
        ]
    },
    {
        name: 'Tacos',
        priceDetail: [
            {
                title: 'Tacos De Carne Asada',
                price: 2.50,
            },
            {
                title: 'Asada con Nopales',
                price: 2.50,
            },
            {
                title: 'Tacos De Pollo Asado',
                price: 2.50,
            },
            {
                title: 'Tacos Adobada',
                price: 2.50,
            },
            {
                title: 'Tacos Longaniza',
                price: 2.50,
            },
            {
                title: 'Fish Taco',
                price: 2.50,
            },
            {
                title: 'Taco Dorado',
                price: 2.50,
            },
            {
                title: 'Rolled Tacos',
                price: 5.99,
            }
        ]
    },
    {
        name: 'Burritos',
        priceDetail: [
            {
                title: 'Burrito De Asada',
                price: 8.25,
            },
            {
                title: 'Mi Ranchito.',
                price: 8.50,
            },
            {
                title: 'Burritos De Pollo Asado',
                price: 7.99,
            },
            {
                title: 'Burritos De Adobada',
                price: 7.99,
            },
            {
                title: 'Hot Cheetos Burrito',
                price: 8.99,
            },
            {
                title: 'Beans & Cheese',
                price: 5.25,
            },
            {
                title: 'California',
                price: 8.25,
            },
            {
                title: 'Camarones/ Shrimp',
                price: 8.25,
            },
            {
                title: 'Vegetarian',
                price: 7.25,
            },
            {
                title: 'Monster Burrito',
                price: 8.75,
            },
            {
                title: 'Baja Burrito',
                price: 8.99,
            }
        ]
    },
    {
        name: 'Caldos',
        priceDetail: [
            {
                title: 'Menudo ',
                price: 8.99,
            },
            {
                title: 'Birria',
                price: 8.75,
            },
            {
                title: 'Caldo de Albondigas',
                price: 8.25,
            },
        ]
    },
]

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    // bgimg={
                                    //     rpdata?.gallery?.length > 1 ?
                                    //         rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                    //         : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    // }
                                    headertitle={item.name}
                                    Subheader={'Our Menu'}
                                />
                                <div className="mainMenu">

                                    <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5 z-10 relative">
                                        <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5 rounded-tl-[40px] rounded-br-[40px] bg-white">
                                            <img
                                                src={item.description[0].img}
                                                alt='no found'
                                                className="w-full object-cover h-auto md:h-[500px] rounded-tl-3xl rounded-br-3xl"
                                            />
                                            <div className="w-full h-auto flex justify-center text-center">
                                                <h3 className="w-auto h-auto py-3 px-8 bg-2 rounded-tl-3xl rounded-br-3xl -mt-12 text-white">
                                                    {
                                                        item.name
                                                    }
                                                </h3>
                                            </div>
                                            <div className="pt-10">
                                                {
                                                    rpdata?.works?.map((items, index) => {
                                                        if (items.service === item.name) {
                                                            return (
                                                                <div key={index} className='w-[90%] mx-auto flex items-center mb-7'>

                                                                    <div>
                                                                        <img
                                                                            src={items.gallery}
                                                                            alt="images menus"
                                                                            className="w-[130px] h-[90px] md:w-[120px] md:h-[100px] rounded-full object-cover shadow-[#9c9c9c] shadow-lg"
                                                                        />
                                                                        <div className="block md:hidden w-full text-center pt-2">
                                                                            {
                                                                                priceMenu.map(({ name, priceDetail }) => {
                                                                                    if (name === item.name) {
                                                                                        return (
                                                                                            priceDetail.map((prices, index) => {
                                                                                                if (prices.title === items.name) {
                                                                                                    return (
                                                                                                        <p key={index} className='text-[#8E101F] font-semibold'>$ {prices.price}</p>
                                                                                                    )
                                                                                                }
                                                                                                return null
                                                                                            })

                                                                                        )
                                                                                    }
                                                                                    return null
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="w-full flex">
                                                                        <div className="w-full md:w-[80%] border-none md:border-dotted md:border-b-2 ">
                                                                            <div className="w-auto ml-3 md:ml-5 flex flex-col justify-center">
                                                                                <p className='capitalize font-semibold text-[20px] '>{items.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="hidden w-[20%] md:flex items-end">
                                                                            <p className=' text-[#404040] font-bold pr-0 md:pr-3'>${items.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            )
                                                        }
                                                        return null
                                                    })
                                                }
                                            </div>

                                            <div className="flex justify-center items-center w-full h-auto pt-10">
                                                <ButtonContent btnStyle={'one'} btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} btnName={'Order Now'} />
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white rounded-tl-3xl rounded-br-3xl ">
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start font-bold py-5"> Our Menu</h4>
                                                <ul className="px-1 md:px-4">
                                                    {
                                                        rpdata?.dbServices?.map((nam, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <Link
                                                                        to={`/${nam.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`}
                                                                        onClick={goToTop}
                                                                        className='flex items-center text-white pb-3'
                                                                    >
                                                                        <FaCaretRight />
                                                                        <span className="pl-2">
                                                                            {nam.name}
                                                                        </span>
                                                                    </Link>
                                                                </li>

                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start font-bold pb-5">Contact</h4>
                                                <ul className="px-1 md:px-4">
                                                    {
                                                        rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                            return (
                                                                <li key={index} className='flex items-center text-white pb-3'>
                                                                    <a
                                                                        href={`tel:+1${phone.phone}`}
                                                                        className='flex items-center'
                                                                    >
                                                                        <BiPhoneCall />
                                                                        <span className="pl-2">
                                                                            {phone.phone}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <a
                                                                        href={`mailto:${email.email}`}
                                                                        className='flex items-center'
                                                                    >
                                                                        <MdOutlineEmail />
                                                                        <span className="pl-2">
                                                                            {email.email}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start font-bold pb-5">Opening Hours</h4>
                                                <ul className="px-1 md:px-4">
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <BiCalendar />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <FaRegClock />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp image={rpdata?.stock?.[6]} />

            <div className="mt-[-110px]">
                <Values />
            </div>
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;